var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"mcnImageBlockInner",style:({ padding: '0px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{staticClass:"mcnImageContentContainer",style:({
        'min-width': '100%',
        'border-collapse': 'collapse',
        'text-size-adjust': '100%',
      }),attrs:{"align":"left","border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"mcnImageContent",style:({
              padding: '0px',
              'text-align': 'center',
              'text-size-adjust': '100%',
            }),attrs:{"valign":"top"}},[_c('img',{staticClass:"mcnImage",style:({
                'object-fit': 'cover',
                'padding-bottom': '0px',
                display: 'inline !important',
                'vertical-align': 'bottom',
                border: '0px',
                outline: 'none',
                'text-decoration': 'none',
                'max-height': '350px',
                'max-width': '700px',
                height: '350px',
                width: '700px',
              }),attrs:{"align":"center","alt":"","src":_vm.backgroundImage,"width":"500","height":"300"}})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }