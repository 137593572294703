var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{style:({ 'padding-top': '9px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{staticClass:"mcnTextContentContainer",style:({
        'max-width': '100%',
        'min-width': '100%',
        'border-collapse': 'collapse',
        'text-size-adjust': '100%',
        'font-family': 'Verdana, Geneva, sans-serif',
        'font-size': '14px',
      }),attrs:{"align":"left","border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{domProps:{"innerHTML":_vm._s(_vm.day.description.value)}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }