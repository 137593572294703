var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{style:({ 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('div',{attrs:{"align":"center"}},[_c('table',{style:({
          'text-align': 'center',
          'min-width': '19%',
          'padding-bottom': '18px',
          margin: 'auto',
          'border-collapse': 'collapse',
          'text-size-adjust': '100%',
          'table-layout': 'fixed !important',
        }),attrs:{"align":"center","border":"0","cellpadding":"0","cellspacing":"0","width":"19%"}},[_c('tbody',{attrs:{"align":"center"}},[_c('tr',[_c('td',{style:({
                'min-width': '100%',
                padding: '0px 18px 9px',
                'text-size-adjust': '100%',
              })},[_c('table',{style:({
                  'min-width': '100%',
                  'border-top': '2px solid #d11762',
                  'border-collapse': 'collapse',
                  'text-size-adjust': '100%',
                }),attrs:{"border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{style:({ 'text-size-adjust': '100%' })},[_c('span',[_vm._v(" ")])])])])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }