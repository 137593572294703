var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{style:({ 'padding-top': '9px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{attrs:{"align":"center","cellpadding":".25rem","cellspacing":"0","width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"has-text-centered",style:({ border: '1px solid #ccc', padding: '6px' })},[_vm._v(" Day(s) ")]),_c('th',{staticClass:"has-text-centered",style:({
              width: '5rem',
              border: '1px solid #ccc',
              padding: '6px',
            })},[_vm._v(" From ")]),_c('th',{staticClass:"has-text-centered",style:({
              width: '5rem',
              border: '1px solid #ccc',
              padding: '6px',
            })},[_vm._v(" To ")]),_c('th',{staticClass:"has-text-centered",style:({ border: '1px solid #ccc', padding: '6px' })},[_vm._v(" Destination ")]),_c('th',{staticClass:"has-text-centered",style:({ border: '1px solid #ccc', padding: '6px' })},[_vm._v(" Level ")]),_c('th',{style:({ border: '1px solid #ccc', padding: '.25rem' })},[_vm._v(" Title ")])])]),_c('tbody',_vm._l((_vm.adaptedItineraryServices),function(itineraryService){return _c('tr',{key:itineraryService.id},[_c('td',{style:({
              'font-size': '12px',
              'font-family': 'Verdana',
              padding: '6px',
              border: '1px solid #ccc',
              'border-collapse': 'collapse',
              'text-align': 'center',
            })},[_vm._v(" "+_vm._s(itineraryService.day)+" ")]),_c('td',{style:({
              'font-size': '12px',
              'font-family': 'Verdana',
              padding: '6px',
              width: '100px',
              border: '1px solid #ccc',
              'border-collapse': 'collapse',
              'text-align': 'center',
            })},[_vm._v(" "+_vm._s(itineraryService.formattedStartDate)+" ")]),_c('td',{style:({
              'font-size': '12px',
              'font-family': 'Verdana',
              padding: '6px',
              width: '100px',
              border: '1px solid #ccc',
              'border-collapse': 'collapse',
              'text-align': 'center',
            })},[_vm._v(" "+_vm._s(itineraryService.formattedEndDate)+" ")]),_c('td',{style:({
              'font-size': '12px',
              'font-family': 'Verdana',
              padding: '6px',
              border: '1px solid #ccc',
              'border-collapse': 'collapse',
              'text-align': 'center',
            })},[_vm._v(" "+_vm._s(itineraryService.destination)+" ")]),_c('td',{style:({
              'font-size': '12px',
              'font-family': 'Verdana',
              padding: '6px',
              border: '1px solid #ccc',
              'border-collapse': 'collapse',
              'text-align': 'center',
            })},[_vm._v(" "+_vm._s(itineraryService.level_text)+" ")]),_c('td',{style:({
              'font-size': '12px',
              'font-family': 'Verdana',
              padding: '6px',
              border: '1px solid #ccc',
              'border-collapse': 'collapse',
            })},[_vm._v(" "+_vm._s(itineraryService.name.value)+" ")])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }