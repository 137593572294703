<template>
  <div>
    <h2 class="TitleUnderConstruction">
      PAGE UNDER<br /><strong>CONSTRUCTION</strong>
    </h2>
    <img
      class="ImageUnderConstruction"
      src="/img/under-constuction.jpg"
      alt="Logo"
    />
  </div>
</template>

<script>
export default {
  name: "UnderConstruction",
};
</script>
