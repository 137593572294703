var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"mcnCaptionBlockInner",style:({ padding: '9px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{staticClass:"mcnCaptionLeftContentOuter",style:({ 'border-collapse': 'collapse', 'text-size-adjust': '100%' }),attrs:{"border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"mcnCaptionLeftContentInner",style:({ padding: '0px 9px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{style:({
                'border-collapse': 'collapse',
                'text-size-adjust': '100%',
              }),attrs:{"align":"center","border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('td',{style:({
                      'text-size-adjust': '100%',
                      'word-break': 'break-word',
                      color: 'rgb(150, 150, 150)',
                      'font-family': 'Verdana, Geneva, sans-serif',
                      'font-size': '14px',
                      'line-height': '200%',
                      'text-align': 'left',
                    }),attrs:{"valign":"top"}},[_c('table',{style:({
                        background: 'rgb(245, 245, 245)',
                        padding: '0px',
                        'font-size': '14px',
                        'border-spacing': '0px',
                        'border-collapse': 'collapse',
                        'text-size-adjust': '100%',
                        'text-align': 'center',
                      })},[_c('tbody',[_c('tr',_vm._l((_vm.quotePricesWithServicesByPriceLevel),function(price,index){return _c('td',{key:index,style:({
                              padding: '5px 10px',
                              border: '0.1rem solid rgb(232, 232, 232)',
                              'border-radius': '30px !important',
                              width: '33% !important',
                            }),attrs:{"valign":"center"}},[_c('p'),_c('p',[_vm._v("Total Price")]),_c('p',{style:({
                                color: 'rgb(111, 111, 111)',
                                'font-weight': 'bold',
                                'font-size': '28px',
                              })},[_vm._v(" $ "+_vm._s(price.total)+" ")]),_c('h6',[_vm._v(_vm._s(price.type)+" Class")]),_c('br'),_vm._l((price.services),function(serv,idx){return _c('span',{key:idx},[_c('p',{style:({
                                  marginBottom: '1em',
                                  lineHeight: '1.25em',
                                  'font-family':
                                    'Verdana, Geneva, sans-serif',
                                })},[_c('span',[_vm._v(_vm._s(serv && "name" in serv ? serv.name.value : ""))]),_c('br')])])}),_c('br')],2)}),0)])])])])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }