var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"mcnImageBlockInner",style:({ padding: '9px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{staticClass:"mcnImageContentContainer",style:({
        'min-width': '100%',
        'border-collapse': 'collapse',
        'text-size-adjust': '100%',
      }),attrs:{"align":"center","border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"mcnImageContent",style:({
              padding: '0px 9px',
              'text-align': 'center',
              'text-size-adjust': '100%',
            }),attrs:{"valign":"top"}},[_c('img',{staticClass:"navbarMainLogo",style:({
                display: 'flex',
                margin: 'auto',
                'object-fit': 'cover',
              }),attrs:{"align":"center","alt":"Encounter Latin America","src":_vm.logoImage}})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }