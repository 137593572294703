var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"mcnImageBlockInner",style:({ padding: '0px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{staticClass:"mcnImageContentContainer",style:({
        'min-width': '100%',
        'border-collapse': 'collapse',
        'text-size-adjust': '100%',
      }),attrs:{"align":"left","border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"mcnImageContent",style:({
              padding: '0px',
              'text-align': 'center',
              'text-size-adjust': '100%',
            }),attrs:{"valign":"top"}},[_c('img',{staticClass:"mcnImage",style:({
                'padding-bottom': '0px',
                display: 'inline !important',
                'vertical-align': 'bottom',
                border: '0px',
                height: '300px',
                outline: 'none',
                'text-decoration': 'none',
                'max-height': '300px',
                'object-fit': 'cover',
              }),attrs:{"align":"center","alt":"","src":'images_full' in _vm.day &&
                _vm.day.images_full &&
                _vm.day.images_full.length
                  ? _vm.day.images_full[0].thumbnail_350X240.replaceAll(
                      ' ',
                      '%20'
                    )
                  : 'https://place-hold.it/700x300/DDDDDD/fff.jpg?bold&fontsize=70&text=Day ' +
                    _vm.day.start_day,"width":"400","height":"240"}})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }