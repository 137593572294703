var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"mcnCaptionBlockInner",style:({ padding: '9px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{staticClass:"mcnCaptionLeftContentOuter",style:({ 'border-collapse': 'collapse', 'text-size-adjust': '100%' }),attrs:{"border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"mcnCaptionLeftContentInner",style:({ padding: '0px 9px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{style:({
                'border-collapse': 'collapse',
                'text-size-adjust': '100%',
              }),attrs:{"align":"center","border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('td',{style:({
                      'text-size-adjust': '100%',
                      'word-break': 'break-word',
                      color: 'rgb(150, 150, 150)',
                      'font-family': 'Verdana, Geneva, sans-serif',
                      'font-size': '14px',
                      'line-height': '200%',
                      'text-align': 'left',
                    }),attrs:{"valign":"top"}},[_c('table',{style:({
                        background: 'white',
                        padding: '0px',
                        color: 'rgb(86, 88, 89)',
                        'font-size': '14px',
                        'font-family': 'Arial, Geneva, sans-serif',
                        'border-spacing': '0px',
                        'border-collapse': 'collapse',
                        'text-size-adjust': '100%',
                      })},[_c('tbody',[_c('tr',[_c('td',{style:({
                              margin: 'auto',
                              'text-align': 'center',
                            }),attrs:{"align":"center","valign":"middle"}},[_c('p',{style:({ 'font-size': '20px' })},[_vm._v(" "+_vm._s(_vm.itinerary.user ? _vm.itinerary.user.name : "")+" ")]),_c('a',{style:({
                                'font-weight': '700',
                                'font-family': 'Arial, Geneva, sans-serif',
                                'text-decoration': 'none',
                                'text-size-adjust': '100%',
                                color: 'rgb(4, 118, 201)',
                              }),attrs:{"href":"mailto:agent@encounterlatinamerica.com","target":"_blank"}},[_c('font',{style:({
                                  'font-size': '12px',
                                  'text-decoration': 'none',
                                }),attrs:{"color":"#666666","face":"Arial"}},[_c('strong',{style:({
                                    color: 'rgb(102, 102, 102)',
                                    'font-weight': 'normal',
                                  })},[_vm._v(_vm._s(_vm.itinerary.user ? _vm.itinerary.user.email : ""))])])],1)])])])])])])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }